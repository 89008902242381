exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-enterprise-recommended-js": () => import("./../../../src/pages/android-enterprise-recommended.js" /* webpackChunkName: "component---src-pages-android-enterprise-recommended-js" */),
  "component---src-pages-android-policy-management-js": () => import("./../../../src/pages/android-policy-management.js" /* webpackChunkName: "component---src-pages-android-policy-management-js" */),
  "component---src-pages-android-work-profile-js": () => import("./../../../src/pages/android-work-profile.js" /* webpackChunkName: "component---src-pages-android-work-profile-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-1-js": () => import("./../../../src/pages/blog-post1.js" /* webpackChunkName: "component---src-pages-blog-post-1-js" */),
  "component---src-pages-blog-post-2-js": () => import("./../../../src/pages/blog-post2.js" /* webpackChunkName: "component---src-pages-blog-post-2-js" */),
  "component---src-pages-blog-post-3-js": () => import("./../../../src/pages/blog-post3.js" /* webpackChunkName: "component---src-pages-blog-post-3-js" */),
  "component---src-pages-bring-your-own-device-js": () => import("./../../../src/pages/bring-your-own-device.js" /* webpackChunkName: "component---src-pages-bring-your-own-device-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enterprise-mobility-management-js": () => import("./../../../src/pages/enterprise-mobility-management.js" /* webpackChunkName: "component---src-pages-enterprise-mobility-management-js" */),
  "component---src-pages-file-manager-android-js": () => import("./../../../src/pages/file-manager-android.js" /* webpackChunkName: "component---src-pages-file-manager-android-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiosk-devices-js": () => import("./../../../src/pages/kiosk-devices.js" /* webpackChunkName: "component---src-pages-kiosk-devices-js" */),
  "component---src-pages-mobile-application-security-js": () => import("./../../../src/pages/mobile-application-security.js" /* webpackChunkName: "component---src-pages-mobile-application-security-js" */),
  "component---src-pages-mobile-device-management-js": () => import("./../../../src/pages/mobile-device-management.js" /* webpackChunkName: "component---src-pages-mobile-device-management-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-slide-js": () => import("./../../../src/pages/slide.js" /* webpackChunkName: "component---src-pages-slide-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-worker-js": () => import("./../../../src/pages/worker.js" /* webpackChunkName: "component---src-pages-worker-js" */),
  "component---src-pages-zero-touch-android-js": () => import("./../../../src/pages/zero-touch-android.js" /* webpackChunkName: "component---src-pages-zero-touch-android-js" */)
}

